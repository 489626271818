<template>
    <div class="col-12">
        <v-data-table
            :headers="headers"
            :height="Addresses.length < 2 ? '160px' : null"
            :items="Addresses"
            calculate-widths
            class="mt-3 appic-table-light specification-table col-lg-9 col-md-12"
            dense
            disable-pagination
            disable-sort
            hide-default-header
            hide-default-footer
            item-key="index"
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-20-pct">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-20-pct">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct ">{{ headers[4].text }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mb-0">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Address.addresstypes="{ item }">
                <v-select
                    :items="addressTypes"
                    :value="item.Address.addresstypes"
                    autocomplete="off"
                    class="custom pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    hide-no-data
                    hide-selected
                    multiple
                    no-filter
                    @change="updateAddressRowHandle( item.index, 'Address.addresstypes',$event)"
                >
                    <template v-slot:selection="data">
                        <v-chip
                            :input-value="data.selected"
                            class="my-1"
                            close
                            small
                            v-bind="data.attrs"
                            @click="data.select"
                            @click:close="removeAddressType(data.item, item.index)"
                        >
                            {{ $t('message.' + data.item.text) }}
                        </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        {{ $t('message.' + item.text) }}
                    </template>
                </v-select>
            </template>
            <template v-slot:item.Address.title="{ item }">
                <v-text-field
                    :value="item.Address.title"
                    class="custom pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    full-width
                    @change="updateAddressRowHandle( item.index, 'Address.title',$event)"
                ></v-text-field>
            </template>
            <template v-slot:item.Address.address="{ item }">
                <v-textarea
                    :value="item.Address.address"
                    class="custom pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    rows="4"
                    full-width
                    @change="updateAddressRowHandle( item.index, 'Address.address',$event)"
                ></v-textarea>
            </template>
            <template v-slot:item.Address.id="{ item }">
                <div class="ml-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Address.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-3 pt-2 px-0 d-flex justify-start">
                        <v-btn
                            @click="addAddressRow(statePrefix)"
                            color="success"
                            small
                        >{{ Addresses.length === 0 ? $t('message.addItem') : $t('message.addMore') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "AddressesList",
        props: ['updateMode'],
        data(){
            return {
                createDialog: false,
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('buyer',{
                Customer__id: 'Customer.id'
            }, 'statePrefix'),
            ...mapMultiRowFieldsPrefixed('customeraddress',{
                Addresses: 'Addresses'
            },'statePrefix'),
            ...mapGetters('customeraddress',{
                addressTypes: 'addressTypes'
            }),
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        id: 1,
                        text: this.$t('message.usage'),
                        value: 'Address.addresstypes'
                    },
                    {
                        id: 2,
                        text: this.$t('message.description'),
                        value: 'Address.title'
                    },
                    {
                        id: 3,
                        text: this.$t('message.address'),
                        value: 'Address.address'
                    },
                    {
                        id: 4,
                        text: this.$t('message.actions'),
                        value: 'Address.id'
                    }
                ]
            }
        },
        methods: {
            ...mapActions('customeraddress',{
                addAddressRow: 'addAddressRow',
                deleteAddress: 'deleteAddress',
                deleteAddressRow: 'deleteAddressRow',
            }),
            async deleteItem(itemIndex,itemId) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteAddressAction'), {color: 'red'})) {
                    let _this = this
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deleteAddressRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=> {
                        if (itemId != null) {
                            this.deleteAddress(
                                {
                                    customer_id: this.Customer__id,
                                    address_id: itemId
                                }
                            )
                        }
                    });
                }
            },
            removeAddressType(item, rowIndex) {
                const index = this.Addresses[rowIndex]['Address']['addresstypes'].indexOf(item.value)
                if(index >= 0) this.Addresses[rowIndex]['Address']['addresstypes'].splice(index, 1)
            },
            updateAddressRowHandle (idx,field,value) {
                if(value === undefined) value = null
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                this.$store.commit('customeraddress/updateAddresses', payload)
            }
        },
        mounted() {
            this.$store.watch(
                function(state) {
                    return state.appic.customeraddress.update.Addresses
                },
                () => {
                    this.updatedBuyerUnsaved = true
                },
                { deep: true }
            )

            this.$store.watch(
                function(state) {
                    return state.appic.customeraddress.current.Addresses
                },
                () => {
                    this.newBuyerUnsaved = true
                },
                { deep: true }
            )
        }
    }
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: left;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px !important;
}
.v-input__append-inner {
    margin-left: 0px !important;
}
</style>