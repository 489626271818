var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table col-lg-9 col-md-12",attrs:{"headers":_vm.headers,"height":_vm.Addresses.length < 2 ? '160px' : null,"items":_vm.Addresses,"calculate-widths":"","dense":"","disable-pagination":"","disable-sort":"","hide-default-header":"","hide-default-footer":"","item-key":"index"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',[_c('th',{staticClass:"grey lighten-3 pa-1 text-center width-1-pct"},[_vm._v(_vm._s(headers[0].text))]),_c('th',{staticClass:"grey lighten-3 pa-1 text-left width-20-pct"},[_vm._v(_vm._s(headers[1].text))]),_c('th',{staticClass:"grey lighten-3 pa-1 text-left width-20-pct"},[_vm._v(_vm._s(headers[2].text))]),_c('th',{staticClass:"grey lighten-3 pa-1 text-left"},[_vm._v(_vm._s(headers[3].text))]),_c('th',{staticClass:"grey lighten-3 pa-1 text-center width-1-pct "},[_vm._v(_vm._s(headers[4].text))])])]}},{key:"item.lineNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(item.lineNumber))])]}},{key:"item.Address.addresstypes",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"custom pa-1 bg-lightgrey",attrs:{"items":_vm.addressTypes,"value":item.Address.addresstypes,"autocomplete":"off","clearable":"","dense":"","hide-details":"auto","hide-no-data":"","hide-selected":"","multiple":"","no-filter":""},on:{"change":function($event){return _vm.updateAddressRowHandle( item.index, 'Address.addresstypes',$event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.removeAddressType(data.item, item.index)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(_vm.$t('message.' + data.item.text))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('message.' + item.text))+" ")]}}],null,true)})]}},{key:"item.Address.title",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"custom pa-1 bg-lightgrey",attrs:{"value":item.Address.title,"clearable":"","dense":"","hide-details":"auto","full-width":""},on:{"change":function($event){return _vm.updateAddressRowHandle( item.index, 'Address.title',$event)}}})]}},{key:"item.Address.address",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"custom pa-1 bg-lightgrey",attrs:{"value":item.Address.address,"clearable":"","dense":"","hide-details":"auto","rows":"4","full-width":""},on:{"change":function($event){return _vm.updateAddressRowHandle( item.index, 'Address.address',$event)}}})]}},{key:"item.Address.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.index, item.Address.id)}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteThisItem')))])])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pt-0 d-flex justify-space-between"},[_c('div',{staticClass:"col-4 col-lg-4 col-md-3 pt-2 px-0 d-flex justify-start"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.addAddressRow(_vm.statePrefix)}}},[_vm._v(_vm._s(_vm.Addresses.length === 0 ? _vm.$t('message.addItem') : _vm.$t('message.addMore'))+" ")])],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }